import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage, siteName} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import PulseLoader from "react-spinners/PulseLoader";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
// import LoadingModal from '../components/modals/LoadingModal';


function Electricity () {
const navigate = useNavigate();

  const fetchBills = async () => {
      dispatch({type: 'start'});
      await axios.post(baseURL+"/bills",  {
    }, {    
        headers:{
            'Authorization':`Bearer ${user().token}`,
            'Content-Type': 'application/json'
        },
      }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          // console.log(response.data);
          PINHandler(!response.data.pin);
              setValue({
                ...value,
                bills: response.data.bills,
              });
              setWallet(response.data.balance);
              dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e) => {
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }

/**************************************************************************/
const [loading, dispatch] = useReducer(loadingReducer, false);
const [pinModal, setPinModal] = useState(false);
const [wallet, setWallet] = useState('');
const [isValidate, setIsValidate] = useState(false);
const [customerName, setCustomerName] = useState('');
const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState({
        phoneNumber: '',
        meterNumber: '',
        type:'',
        img: '/dashboard/images/bulb.png',
        imgWidth: '35px',
        bills: [],
        amount: '',
        disco: '',
        selected_disco: [],
  });


  function billsHandler(e) {
    var disco = e.target.value;
    document.getElementById("type").selectedIndex = 0;
    if (disco !== "") {
      var bills = value.bills.filter((e) => {
        return e.disco == disco;
    })
    // console.log(bills);
        setValue({...value, disco: disco, selected_disco: bills});
    } else {
      setValue({...value, disco: '', meterNumber: '', type: '', amount: '', selected_disco: []});
    }
  }


/**************************************************************************/

function notValidated() {
  setValidateLoading(false);
  setIsValidate(false);
}


function validate() {
  setValidateLoading(true);
  axios.post(baseURL+"/bills/validate", {
    id: value.selected_disco[0].id,
    meter_number: value.meterNumber,
    type: value.type
  },{    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (response.data.status == "success" && response.data.name !== "") {
        setCustomerName(response.data.name);
        setModalShow(true);
        setValidateLoading(false);
      } else if (response.data.status == "failed" && response.data.message !== "") {
        notValidated();
        return toaster("error", response.data.message);
      } else {
        notValidated();
        return toaster("error", error.whoops);  
      }

    }).catch((e)=>{
      notValidated();
      if (e.response !== undefined) {
        if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
            storage("remove", "data", null, "localStorage");
            return navigate("/login");
        }
      }
      return toaster("error", error.whoops);
        // console.log(e);
    })

}



function proceed(e) {
  e.preventDefault();
  if (value.disco == "") return toaster("error", error.validation.emptyDisco);
  if (value.type == '') return toaster("error", error.validation.emptyType);
  if (value.selected_disco.length !== 1) return toaster("error", error.validation.emptyDisco);
  if (value.meterNumber == '' || value.meterNumber.length < 6) return toaster("error", error.validation.invalidDisco);
  if (value.amount == '' || value.amount < 1) return toaster("error", error.validation.emptyAmount);
  if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
  if (value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
  if (value.amount < 2000) return toaster("error", "The amount entered falls short of the required minimum (₦2000).");
  if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);

  if (isValidate == false) { 
    validate();
    setIsValidate(true);
    return;
  }

  if (customerName !== "") {
  let object = {
      "service": "Bills Payment",
      "id" : value.selected_disco[0].id,
      "disco" : value.disco,
      "number": value.phoneNumber,
      "type": value.type,
      "amount": value.amount,
      "customerName": customerName,
      "meterNumber": value.meterNumber,
  };
  object = JSON.stringify(object);
  if(storage("save", "invoice", object, "sessionStorage")) {
      navigate("/invoice");
  } else {
      return alert(error.whoops);
  }
  } else {
    return toaster("error", error.validation.emptyCustomerName);
  }

}

const PINHandler = (value) => {
  setPinModal(value);
}

const hideModal = () => {
  setModalShow(false);
  setIsValidate(false);
}

const btnTxt = isValidate === true ? "Proceed" : "Validate";
const [validateLoading, setValidateLoading] = useState(false);


useEffect(() => {
  scrollToTop();
  fetchBills();
  return () => axios.CancelToken.source();
},[]);

return (
    <div>


    {/* Top Bar  */}
    <TopBar icon="fas fa-lightbulb"/>

    <div className={"page-content " + Styles.page_content}>


      {/* Top Card */}
      <TopCard wallet={wallet}/>

    <div className="card card-clear" style={{ height: "170px" }}></div>



{/* Confirmation Modal */}

<Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}>
        <img src="/dashboard/images/electricity-power-line.jpg" 
              style={{ width: "70px", height: "70px", borderRadius: "50%" }} 
            />  
          {/* <img src="/dashboard/images/ibedc-electricity.png" width={"50px"} />  */}
        </div>
        <h4 className='text-center'> {customerName} </h4>      
        <p style={{ marginBottom: "0px" }}>
        <i> Please double-check that the above name corresponds to the customer's name. ⚠️</i>
        </p>
        <table className='table table-striped table-bordered' style={{ width:'100%', marginBottom: '1px' }}>
                <tbody>
                    <tr>
                    <td style={{ fontSize: '12px' }}> <b>Disco: <span style={{ color: "blue" }}> {value.disco !== '' ? value.disco : ''}  </span>  </b> </td>

                        <td style={{ fontSize: '12px' }}> <b> {value.type !== '' ? value.type : ''} </b> </td>

                        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "green" }}><b> ₦{value.amount.length > 1 ? value.amount : ''} </b></span> </td>
                     </tr>
                    <tr>
                        <th scope='row' style={{ fontSize: '12px' }}> Meter Number </th>
                        <td colSpan='2' style={{ fontSize: '12px' }}> <span style={{ color: "red"}}><b> { value.meterNumber !== '' ? value.meterNumber : '' } </b> </span> </td>
                    </tr>
            </tbody>
        </table>
        <div style={{ marginTop: "20px" }}>
          <Button style={{ float: "left" }} onClick={hideModal} className='mr-auto btn-danger btn-sm'>Cancel</Button>
          <Button style={{ float: "right" }} onClick={proceed}  className='btn-success btn-sm btn-block'>Yes, it's correct</Button>
        </div>

      </Modal.Body>

    </Modal>

    <SetPin visibility={pinModal} state={PINHandler} />

<div className={"page-content pb-0 " + Styles.pt_10}>

<ToastContainer/>

<div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>



<div className={"content mb-0 " + Styles.pt_13}>

<div hidden={!loading}>

<div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>



<div>
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme>
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme>
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
</div>

{loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}

</div>



{/* <LoadingModal text="" key="load" visibility={loading}/> */}



<span hidden={loading}>
<h3><span> Electricity </span>

<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: ""}}>
      <img src={ value.img } className="text-center" width={value.imgWidth} style={{  borderRadius: "0%"  }} />
      </span></h3>
<p className={ Styles.description_txt }>

If you receive your 💡 bills from NEPA monthly choose POSTPAID.

</p>



<div className="input-style has-borders no-icon mb-4">

        <select id="disco" onChange={billsHandler} value={value.disco} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.bills.length !== 0 ? value.bills.map((e, index) => {        
          return <option value={e.disco} key={index}> {e.disco} </option> 
          }) : ''}
        </select>


        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>


  <div className="input-style has-borders no-icon mb-4" hidden={value.disco == '' ? false : false}>
        {/* <label htmlFor="form5" className="color-highlight">Plan</label> */}
        <select id="type" onChange={(e) => setValue({...value, type: e.target.value, meterNumber: ''})} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Select meter type</option>
        <option value="PREPAID"> PREPAID </option>
        <option value="POSTPAID"> POSTPAID </option>
        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>




  <div className="input-style has-borders has-icon validate-field mb-4" hidden={value.type !== '' ? false : false}>
    <i className="fa fa-arrow-right" style={{ color: '#ed0010' }}></i>
    <input type="number" autoComplete="off" value={value.meterNumber} onChange={(e) => setValue({...value, meterNumber: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="type" placeholder="Enter meter number" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>


  <div className="input-style has-borders has-icon validate-field mb-4" hidden={value.meterNumber !== '' ? false : false}>
    <i className="fas fa-money-bill-alt" style={{ color: "#152ba6" }}></i>
    <input type="number" autoComplete="off" value={value.amount} onChange={(e) => setValue({...value, amount: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="amount" placeholder="Enter amount" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>



  <div className="input-style has-borders has-icon validate-field mb-4" hidden={false}>
    <i className="fa fa-phone" style={{ color: "#008000" }}></i>
    <input type="number" autoComplete="off" value={value.phoneNumber} onChange={(e) => setValue({...value, phoneNumber: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Phone Number" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>



  <a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>

{ validateLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 { validateLoading ? '' : btnTxt }
  </a>

  </span>




            </div>
        </div>
        {/* <div className="mb-5"></div> */}


    </div>

</div>


</div>

    );
}


export default Electricity;
